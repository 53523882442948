import React from 'react';
import { Layout, SEO } from '../layout';
import {
	FadeInWhenVisibleLeft,
	FadeInWhenVisibleRight,
} from '../components/FadeInWhenVisible';

import 'swiper/css';
import 'swiper/css/navigation';

import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { motion } from 'framer-motion';
import WithTransition from '../components/WithTransition';

SwiperCore.use([Navigation]);

const CarouselImages = () => {
	return (
		<>
			<Swiper
				pagination={{
					type: 'fraction',
				}}
				navigation={true}
				className="mySwiper">
				
				<SwiperSlide>
					{' '}
					<img
						src="../images/Seiva/img11.jpeg"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					{' '}
					<img
						src="../images/Seiva/img1.jpeg"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<img
						src="../images/Seiva/img14.JPG"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<img
						src="../images/Seiva/img10.jpeg"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<img
						src="../images/Seiva/img5.jpeg"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					{' '}
					<img
						src="../images/Seiva/img7.jpeg"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					{' '}
					<img
						src="../images/Seiva/img6.jpeg"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
			</Swiper>
		</>
	);
};

function PageJobMSeiva() {
	const fadeLeft = {
		hidden: { opacity: 0, y: 100 },
		visible: { opacity: 1, y: 0 },
	};
	return (
		<Layout>
			<SEO title="Seiva" />
			<section className="section-padding">
				<div className=" flow">
					<section className="container_page_jobs">
						<motion.h1
							className="title_page"
							variants={fadeLeft}
							initial="hidden"
							animate="visible"
							transition={{ duration: 1 }}>
							Seiva
						</motion.h1>

						<motion.img
							whileTap={{ scale: 0.9 }}
							drag={true}
							dragConstraints={{
								left: 0,
								right: 250,
								top: 0,
								bottom: 50,
							}}
							initial={{ opacity: 0, y: -100 }}
							animate={{
								opacity: 1,
								y: 0,
								transition: { duration: 1 },
							}}
							className="first_image"
							src="../images/Seiva/img4.jpeg"
						/>
						<FadeInWhenVisibleRight>
							<div className="container_text_job">
								<p className="text">
									Seiva é uma proposta performática dançada e
									produzida em formato de vídeo, que busca
									relacionar a experiência erótica juntamente
									da interação com a arquitetura de um espaço
									abandonado em estado de degradação, o desejo
									da performance é “ouvir” os fluídos que
									circulam por entre as superfícies em ruínas.
									A performance se propõe a buscar vitalidade
									naquilo que, supostamente, não há vida.
									Interagir com o antigo em busca de forças e
									motivações atuais. Numa tentativa quase
									telepática de trocar mistérios e evocar
									forças armazenadas em arquiteturas
									destruídas. O movimento nasce a partir da
									escuta da umidade do espaço e como estas
									forças se agenciam compondo uma dramaturgia
									em tempo real.
								</p>
							</div>
						</FadeInWhenVisibleRight>
						<FadeInWhenVisibleLeft>
							<div className="container_text_job">
								<img
									className="second_image"
									src="../images/Seiva/img13.JPG"
								/>
							</div>
						</FadeInWhenVisibleLeft>
						<FadeInWhenVisibleRight>
							<div className="container_text_job"></div>
						</FadeInWhenVisibleRight>
						<CarouselImages />
						<div className="infos">
							<p className="text_infos"></p>
							<img className="image_infos"></img>
						</div>
					</section>
				</div>
				<div className="section_infos">
					<section className="container_infos">
						<p className="text_bold">Ficha técnica:</p>
						<div className="texts_infos">
							<p className="text_bold">Criação y Performance:</p>
							<p className="text_indent">Mirê Pi</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Direção de Fotografia:</p>
							<p className="text_indent">Luiza Leal</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">
								Assistente de câmera e Produção:
							</p>
							<p className="text_indent">Amanda Môa</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Duração:</p>
							<p className="text_indent">7:48min</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Livre</p>
						</div>
						<br></br>
						<p className="text_bold">Release:</p>
						<p className="text_release">
							{' '}
							Seiva é uma experiência performática arqueológica y
							afetiva com o desejo de tocar em fluídos de
							superfícies em ruínas, como quem toca em princípios
							vitais da existência, numa tentativa quase
							telepática de trocar mistérios e evocar forças
							armazenadas em arquiteturas destruídas.
						</p>
					</section>
				</div>
			</section>
		</Layout>
	);
}

export default WithTransition(PageJobMSeiva);
